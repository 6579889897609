import React from "react";
import ReactDOM from "react-dom/client";
import { ErrorBoundary } from "react-error-boundary";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import "./index.css";

import "react-loading-skeleton/dist/skeleton.css";
import App from "./App";
import { Fallback } from "./ErrorBoundaryFallback";
import { store } from "./redux/store";
import reportWebVitals from "./reportWebVitals";
import "./styles/styles.scss";

const root = ReactDOM.createRoot(
	document.getElementById("root") as HTMLElement
);
root.render(
	<Provider store={store}>
		<BrowserRouter>
			<ErrorBoundary FallbackComponent={Fallback}>
				<App />
			</ErrorBoundary>
		</BrowserRouter>
	</Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
