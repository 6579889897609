import type {
	TTapeSearchByIdResponseProps,
	TTapeSearchParameters,
	TTapeSearchResponseProps,
} from "../types/tapes.type";
import type { AxiosRequestConfig } from "axios";

import { getEndpoint } from "../endpoints";
import { type TAnalysisResponse } from "../types/analysis.type";

import {
	httpServiceInstance,
	type HttpService,
} from "./http.service";

/**
 * Service class for managing tenants.
 */
export class AnalysisService {
	private static readonly instance: AnalysisService;
	private static http: HttpService;
	private static endpoint: string;

	/**
	 * Private constructor to prevent instantiation.
	 * Initializes the HttpService instance.
	 */
	constructor() {
		AnalysisService.http = httpServiceInstance;
		AnalysisService.endpoint = getEndpoint();
	}

	/**
	 * This method is responsible for starting the analysis
	 * @returns A promise that resolves to the sas link that will be polled later.
	 */
	async startAnalysis(
		formData: FormData
	): Promise<TAnalysisResponse["data"]> {
		const url = `${AnalysisService.endpoint}/api/analyze`;
		// console.log("service", { formData });
		const config = {
			data: formData,
			headers: {
				"Content-Type": "multipart/form-data",
			},
		};

		const { data } = await AnalysisService.http.post<
			TAnalysisResponse["data"]
		>(url, config);
		return data;
	}

	async restartAnalysis(
		tapeId: string
	): Promise<TAnalysisResponse["data"]> {
		const url = `${AnalysisService.endpoint}/api/analysis/${tapeId}`;

		// Change to post request

		const { data } =
			await AnalysisService.http.post<
				TAnalysisResponse["data"]
			>(url);
		return data;
	}

	/**
	 *
	 * @param url : This URL is the sas link that will be polled for status.
	 * @returns : A promise that resolves to the analysis result data or provide data for the current status of polling.
	 */

	async pollingAnalysis(url: string): Promise<any> {
		try {
			const { data } =
				await AnalysisService.http.getWithoutAuth<TAnalysisResponse>(
					url
				);
			return data;
		} catch (error: any) {
			return error.response;
		}
	}

	async getAllTapes(): Promise<any> {
		const url = `${AnalysisService.endpoint}/api/tapes`;
		try {
			const { data } =
				await AnalysisService.http.get<any>(url);
			return data;
		} catch (error: any) {
			return error.response;
		}
	}

	async getTapeById(
		tapeId: string,
		config?: AxiosRequestConfig
	): Promise<any> {
		const url = `${AnalysisService.endpoint}/api/sanitization/meta/${tapeId}`;
		try {
			const { data } = await AnalysisService.http.get<any>(
				url,
				config
			);
			return data;
		} catch (error: any) {
			throw error.response;
		}
	}

	async getAnalysisData(
		tapeId: string,
		config?: AxiosRequestConfig
	): Promise<any> {
		const url = `${AnalysisService.endpoint}/api/analysis/${tapeId}`;
		try {
			const { data } = await AnalysisService.http.get<any>(
				url,
				config
			);
			return data;
		} catch (error: any) {
			throw error.response;
		}
	}

	async searchTapes(
		request: TTapeSearchParameters,
		config?: AxiosRequestConfig
	): Promise<TTapeSearchResponseProps> {
		const url = `${AnalysisService.endpoint}/api/search?page=${request.page}`;
		try {
			const { data } =
				await AnalysisService.http.post<TTapeSearchResponseProps>(
					url,
					{
						data: request.form,
						...config,
					}
				);
			return data;
		} catch (error: any) {
			return {
				tapes: [],
				count: 0,
				error: error.response,
			};
		}
	}

	async searchTapeById(
		tapeId: string
	): Promise<TTapeSearchByIdResponseProps> {
		const url = `${AnalysisService.endpoint}/api/tapes/${tapeId}`;
		try {
			const { data } =
				await AnalysisService.http.get<TTapeSearchByIdResponseProps>(
					url
				);
			return data;
		} catch (error: any) {
			return {
				allowed: false,
				tape: undefined,
				message: error.response,
			};
		}
	}
}
