import { type TError } from "./common.type";
import {
	type TFinalAnalysisOutput,
	type TLoanTapeStats,
} from "./tapes.type";

export type TAnalysisPayload = {
	file: File;
};

export type TAnalysisResponse = {
	data: {
		message: string;
		status_response: {
			id: string;
			status: string;
			tape_id: string;
		};
	};
	status: number;
};

export type TActiveAnalysisData = {
	label: string;
	values: Record<string, Array<any>>;
	scenarios: Array<string>;
};

export type TSanitizedActiveAnalysisData = {
	basePrice: string;
	plotValues: Record<string, Array<string | number>>;
	cashFlowLinks: Array<string>;
};

export type TAnalysisState = {
	activeAnalysis: TPollingDetails | null;
	activeAnalysisData: TActiveAnalysisData | null;
	selectedDataPoint: TFinalAnalysisOutput | null;
	currentAnalysis: {
		isLoading: boolean;
		data: any;
		status: AnalysisStageEnum | null;
	};
	activeTapeId: string | null;
	error?: TError;
	isLoading: boolean;
	originalTapeData: {
		keyOutputs: Record<string, string | number>;
		assumptions: Record<string, string | number>;
		loanTapeStats: Array<TLoanTapeStats>;
		graphData: Record<string, Array<any>>;
	} | null;
	tapeUploadStatus: {
		loading: boolean;
		error: string | null;
		status:
			| "success"
			| "not-started"
			| "in-progress"
			| "failed";
	};
};

export type TPollingDetails = {
	id: string;
	status: string;
	sas: string;
};

export enum AnalysisStageEnum {
	UPLOADED = "UPLOADED",
	PRICING_FAILED = "PRICING_FAILED",
	SANITIZATION_COMPLETED = "SANITIZATION_COMPLETED",
	CASHFLOW_GENERATED = "CASHFLOW_GENERATED",
	PRICING_IN_PROGRESS = "PRICING_IN_PROGRESS",
	PRICING_COMPLETED = "PRICING_COMPLETED",
	TAPE_ALREADY_EXISTS = "TAPE_ALREADY_EXISTS",
	CALIPER_RUN_FAILED = "CALIPER_RUN_FAILED",
	SEND_EMAIL_FAILED = "SEND_EMAIL_FAILED",
	TAPE_SANITIZATION_FAILED = "SANITIZATION_FAILED",
	CASH_FLOW_GENERATION_FAILED = "CASH_FLOW_GENERATION_FAILED",
}
