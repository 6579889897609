import React, {
	createContext,
	useCallback,
	useEffect,
	useRef,
	useState,
	type ReactElement,
} from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import type {
	AssetClassEnum,
	TTape,
} from "../types/tapes.type";

import { AnalysisActions } from "../redux/analysis/analysis.slice";
import { startAnalysisThunk } from "../redux/analysis/analysis.thunk";
import { uploadTapeThunk } from "../redux/sanitization/sanitization.thunk";
import {
	useAppDispatch,
	useAppSelector,
} from "../redux/store";
import { TapeSliceActions } from "../redux/tapes/tapes.slice";
import { UserAuthActions } from "../redux/user/user.slice";
import { services } from "../services";
import { AnalysisStageEnum } from "../types/analysis.type";
import { generatedPlotValues } from "../utils/analysisUtils";

type TTapeExist = {
	isCheckDone: boolean;
	exist: boolean;
	tape: TTape | null;
};

// Create the context
export const AnalysisContext = createContext<any>(null);

// Create the provider component
export const AnalysisProvider: React.FC<{
	children: ReactElement;
}> = ({ children }) => {
	const [point, setPoint] = useState<any>(null);
	const [downloadLink, setDownloadLink] =
		useState<string>("");
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const { activeTapeId, currentAnalysis } = useAppSelector(
		(state) => state.analysis
	);

	const [isAvailable, setIsAvailable] = useState({
		keyOutputs: false,
		assumptions: false,
		loanTapeStats: false,
		finalOutput: false,
	});
	const [graphData, setGraphData] = useState<any>(null);
	const [graphLabels, setGraphLabels] = useState<any>(null);

	const selectedIndex = useRef<any>(null);

	const [pollingResponse, setPollingResponse] =
		useState<any>(null);

	const [refreshAvailable, setRefreshAvailable] =
		useState(false);

	const { allTapes } = useAppSelector(
		(state) => state.tapes
	);
	const { activeTenant } = useAppSelector(
		(state) => state.tenant
	);

	const { user } = useAppSelector((state) => state.user);

	const timeout = useRef<NodeJS.Timeout | null>(null);
	const isTapeSwitched = useRef(false);

	const [errorOccurred, setErrorOccurred] = useState<any>({
		isError: false,
		tapeId: null,
		status: null,
	});

	const controller = useRef<any>({});

	const [tapeExist, setTapeExist] = useState<TTapeExist>({
		isCheckDone: false,
		exist: false,
		tape: null,
	});

	const getTape = useCallback(
		async (tapeId: string) => {
			const tapeStatus =
				await services.analysisServiceInstance.searchTapeById(
					tapeId
				);
			if (tapeStatus.allowed && tapeStatus.tape) {
				setTapeExist({
					isCheckDone: true,
					exist: true,
					tape: tapeStatus.tape,
				});
				dispatch(TapeSliceActions.addTape(tapeStatus.tape));
			} else {
				setTapeExist({
					isCheckDone: true,
					exist: false,
					tape: null,
				});
			}
		},
		[dispatch]
	);

	const resetEverything = useCallback(
		(stopLoading?: boolean) => {
			console.log("Resetting everything");
			clearTimeout(timeout.current as NodeJS.Timeout);
			dispatch(
				AnalysisActions.setCurrentAnalysisData(null)
			);
			dispatch(AnalysisActions.setOriginalTapeData(null));
			setPoint(null);
			setDownloadLink("");
			dispatch(AnalysisActions.setSelectedDataPoint(null));
			setRefreshAvailable(false);
			setPollingResponse(null);
			selectedIndex.current = null;
			setIsAvailable({
				keyOutputs: false,
				assumptions: false,
				loanTapeStats: false,
				finalOutput: false,
			});
			setGraphData(null);
			setGraphLabels(null);
			setErrorOccurred({
				isError: false,
				tapeId: null,
				status: null,
			});
			dispatch(
				AnalysisActions.setCurrentAnalysisLoading(
					!stopLoading
				)
			);

			isTapeSwitched.current = true;
		},
		[dispatch, timeout]
	);

	useEffect(() => {
		if (activeTenant && user) {
			dispatch(
				UserAuthActions.updateGlobalAdmin(activeTenant)
			);
		}
	}, [activeTenant, user, dispatch]);

	const uploadTape = useCallback(
		(
			file: File,
			tapeName: string,
			assetClass: AssetClassEnum
		) => {
			void dispatch(
				uploadTapeThunk({
					file,
					name: tapeName,
					assetClass,
				})
			);
		},
		[dispatch]
	);

	const handlePlotting = useCallback(
		(data: Array<any>, base_output: any) => {
			const temp = {
				price: base_output.price,
				assumptions: base_output.assumptions,
				cashflow_url: undefined,
				graph_data: base_output.graph_data,
				category: base_output.category,
			};
			const arr = [temp, ...data];
			const res = generatedPlotValues(arr);
			console.log({ res });
			dispatch(AnalysisActions.setCurrentAnalysisData(res));
		},
		[dispatch]
	);

	const updateWithNewData = useCallback(
		(tapeId: string, firstRes?: any) => {
			const res = firstRes || pollingResponse;
			dispatch(
				TapeSliceActions.updateTape({
					id: tapeId,
					payload: {
						status: res.status,
						data: res.result,
					},
				})
			);
			setIsAvailable({
				keyOutputs: true,
				assumptions: true,
				loanTapeStats: true,
				finalOutput: true,
			});
			handlePlotting(
				res.result.final_output,
				res.result.base_output
			);
		},
		[pollingResponse, dispatch, handlePlotting]
	);

	const [tapeWithError, setTapeWithError] = useState<
		string | null
	>(null);

	const continuePolling = useRef(true);

	// Function to handle polling for analysis status, once the analysis is done we should receive the status as "Pricing completed"
	const handlePolling = useCallback(
		async (tapeId: string) => {
			console.log("Polling", tapeId);
			if (!continuePolling.current) return;
			try {
				const res =
					await services.analysisServiceInstance.getAnalysisData(
						tapeId,
						{
							signal: controller.current[tapeId].signal,
						}
					);
				dispatch(
					AnalysisActions.setCurrentAnalysisStatus(
						res.status
					)
				);
				switch (res.status) {
					case AnalysisStageEnum.PRICING_FAILED:
					case AnalysisStageEnum.CASH_FLOW_GENERATION_FAILED:
					case AnalysisStageEnum.TAPE_ALREADY_EXISTS: {
						console.log("Pricing failed", res.status);
						setTapeWithError(tapeId);
						dispatch(AnalysisActions.setActiveTapeId(null));
						void dispatch(
							AnalysisActions.setCurrentAnalysisLoading(
								false
							)
						);
						isTapeSwitched.current = true;
						if (controller.current[tapeId]) {
							controller.current[tapeId].abort();
						}
						setErrorOccurred({
							isError: true,
							tapeId,
							status: res.status,
						});
						return;
					}
					case AnalysisStageEnum.TAPE_SANITIZATION_FAILED:
					case AnalysisStageEnum.CALIPER_RUN_FAILED: {
						console.log("Pricing failed", res.status);
						// dispatch(AnalysisActions.setActiveTapeId(null));
						setTapeWithError(tapeId);
						void dispatch(
							AnalysisActions.setCurrentAnalysisLoading(
								false
							)
						);
						isTapeSwitched.current = true;
						if (controller.current[tapeId]) {
							controller.current[tapeId].abort();
						}
						setErrorOccurred({
							isError: true,
							tapeId,
							status: res.status,
						});
						return;
					}
					case AnalysisStageEnum.SANITIZATION_COMPLETED: {
						console.log("Sanitization completed");
						break;
					}
					case AnalysisStageEnum.CASHFLOW_GENERATED: {
						// if (res.result) {
						// 	dispatch(
						// 		TapeSliceActions.updateTape({
						// 			id: tapeId,
						// 			payload: {
						// 				status: res.status,
						// 				data: res.result,
						// 			},
						// 		})
						// 	);
						// 	setIsAvailable((prev) => {
						// 		return {
						// 			...prev,
						// 			keyOutputs: true,
						// 			assumptions: true,
						// 			loanTapeStats: true,
						// 		};
						// 	});
						// }
						break;
					}
					case AnalysisStageEnum.PRICING_IN_PROGRESS: {
						if (res.result?.base_output) {
							updateWithNewData(tapeId, res);
							isTapeSwitched.current = false;
						}
						break;
					}
					case AnalysisStageEnum.PRICING_COMPLETED: {
						if (res.result) {
							console.log("Pricing completed", res);
							updateWithNewData(tapeId, res);
							isTapeSwitched.current = false;
							dispatch(
								AnalysisActions.setCurrentAnalysisLoading(
									false
								)
							);
						}
						break;
					}
					default: {
						console.log("Unknown status", res.status);
						break;
					}
				}
				if (
					res.status !== AnalysisStageEnum.PRICING_COMPLETED
				) {
					timeout.current = setTimeout(() => {
						continuePolling.current = true;
						void handlePolling(tapeId);
					}, 15000);
				} else {
					console.log("Polling stopped", res);
					continuePolling.current = false;
				}
			} catch (error) {
				console.error("handling polling", error);
				setErrorOccurred({
					isError: true,
					tapeId,
					status: AnalysisStageEnum.PRICING_FAILED,
				});
				setTapeWithError(tapeId);
				dispatch(
					AnalysisActions.setCurrentAnalysisStatus(
						AnalysisStageEnum.PRICING_FAILED
					)
				);
			}
		},
		[dispatch, updateWithNewData, controller]
	);

	useEffect(() => {
		if (
			isAvailable.keyOutputs &&
			isAvailable.assumptions &&
			activeTapeId
		) {
			const tape = allTapes[activeTapeId];
			if (tape) {
				const originalData = {
					keyOutputs: tape.analysisData?.keyOutputs,
					assumptions: tape.analysisData?.assumptions,
					loanTapeStats: tape.analysisData?.loanTapeStats,
					graphData: tape.analysisData?.baseOutputGraphData,
				};
				dispatch(
					AnalysisActions.setOriginalTapeData(originalData)
				);
			}
		}
		// console.log({ isAvailable });
	}, [isAvailable, activeTapeId, allTapes, dispatch]);

	// Function to handle axis combination selection
	const handleAxisCombination = useCallback(
		(axisCombination: any) => {
			const curr = currentAnalysis.data;
			// dispatch(AnalysisActions.setActiveAnalysis(curr));
			const temp = {
				x: curr[axisCombination.x],
				y: curr[axisCombination.y],
				z: axisCombination.z
					? curr[axisCombination.z]
					: null,
			};
			setGraphLabels(axisCombination);
			setGraphData(temp);
		},
		[currentAnalysis.data]
	);

	const handlePointSelection = useCallback(
		(index: number) => {
			if (activeTapeId) {
				const tape = allTapes[activeTapeId];
				if (tape) {
					const point =
						tape.analysisData?.finalOutput[index];
					dispatch(
						AnalysisActions.setSelectedDataPoint(point)
					);
				}
			}
		},
		[allTapes, activeTapeId, dispatch]
	);

	const handleTapeChange = useCallback(
		(tapeId: string) => {
			resetEverything();
			controller.current[tapeId] = new AbortController();
			if (activeTapeId && tapeId !== activeTapeId) {
				if (controller.current[activeTapeId]) {
					controller.current[activeTapeId].abort();
				}
			}
			void handlePolling(tapeId);
			void dispatch(
				AnalysisActions.setCurrentAnalysisLoading(true)
			);
			isTapeSwitched.current = true;
		},
		[resetEverything, dispatch, handlePolling, activeTapeId]
	);

	useEffect(() => {
		if (
			activeTapeId &&
			tapeExist.isCheckDone &&
			tapeExist.exist
		) {
			handleTapeChange(activeTapeId);
		} else if (
			activeTapeId &&
			tapeExist.isCheckDone &&
			!tapeExist.exist
		) {
			resetEverything();
			toast.error("Tape not found");
			navigate("/");
		}
	}, [
		activeTapeId,
		handleTapeChange,
		tapeExist,
		navigate,
		resetEverything,
	]);

	//  states and functions exposed to the children components
	const value = {
		point,
		setPoint,
		downloadLink,
		setDownloadLink,
		uploadTape,
		isAvailable,
		handleAxisCombination,
		graphData,
		graphLabels,
		handlePointSelection,
		refreshAvailable,
		selectedIndex,
		handleTapeChange,
		resetEverything,
		tapeWithError,
		errorOccurred,
		setErrorOccurred,
		getTape,
		tapeExist,
	};
	return (
		<AnalysisContext.Provider value={value}>
			{children}
		</AnalysisContext.Provider>
	);
};
