import type {
	TCreateSimulationRequestObject,
	TFilterRequestObject,
	TGetTagRequestObject,
	TSaveSimulationRequestObject,
	TSimulationAssumptionRequestObject,
} from "../types/simulation.type";
import type { AxiosRequestConfig } from "axios";

import { getEndpoint } from "../endpoints";

import {
	httpServiceInstance,
	type HttpService,
} from "./http.service";

export class SimulationService {
	private static readonly instance: SimulationService;
	private static http: HttpService;
	private static endpoint: string;
	private static base_url: string;
	constructor() {
		SimulationService.http = httpServiceInstance;
		SimulationService.endpoint = getEndpoint();
		SimulationService.base_url = `${SimulationService.endpoint}/api`;
	}

	async getTapeForSimulation(tapeId: string): Promise<any> {
		const url = `${SimulationService.base_url}/tapes/${tapeId}`;

		const { data: response } =
			await SimulationService.http.get<any>(url);
		return response;
	}

	async getPointsForSimulation(
		tapeId: string,
		filterObject: any,
		config?: AxiosRequestConfig
	): Promise<any> {
		const url = `${SimulationService.base_url}/points/${tapeId}`;

		const { data: response } =
			await SimulationService.http.post<any>(url, {
				data: filterObject,
				signal: config?.signal,
			});
		return response;
	}

	async getValuationQuestion(
		isNewRunRequested: boolean,
		tapeId?: string,
		runId?: string | undefined
	): Promise<any> {
		const url = `${SimulationService.base_url}/valuation`;
		const { data: response } =
			await SimulationService.http.post<any>(url, {
				data: {
					tape_id: tapeId,
					run_id: runId,
					is_new_run_requested: isNewRunRequested,
				},
			});
		return response;
	}

	async saveAnswer({
		questionId,
		userInput,
		runId,
		userInputType,
	}: TSimulationAssumptionRequestObject): Promise<any> {
		const url = `${SimulationService.base_url}/valuation/${runId}`;
		const { data: response } =
			await SimulationService.http.post<any>(url, {
				data: {
					question_id: questionId,
					user_input: userInput,
					user_input_type: userInputType,
				},
			});
		return response;
	}

	async createNewSimulation({
		runId,
		tapeId,
	}: TCreateSimulationRequestObject): Promise<any> {
		const url = `${SimulationService.base_url}/valuation/confirm/${runId}`;
		const { data: response } =
			await SimulationService.http.post<any>(url, {
				data: {
					tape_id: tapeId,
				},
			});
		return response;
	}

	async saveSimulation({
		runId,
		tapeId,
		runName,
	}: TSaveSimulationRequestObject): Promise<any> {
		const url = `${SimulationService.base_url}/valuation/save/${runId}`;
		const { data: response } =
			await SimulationService.http.post<any>(url, {
				data: {
					tape_id: tapeId,
					run_name: runName,
				},
			});
		return response;
	}

	async getFilterParameters(
		req: TFilterRequestObject
	): Promise<any> {
		const url = `${SimulationService.base_url}/points/filters`;
		const { data: response } =
			await SimulationService.http.post<any>(url, {
				data: {
					tape_id: req.tapeId,
					run_id: req.runId,
				},
			});
		return response;
	}

	async saveTag(req: TGetTagRequestObject): Promise<any> {
		const url = `${SimulationService.base_url}/points/tag`;
		const { data: response } =
			await SimulationService.http.post<any>(url, {
				data: {
					tape_id: req.tapeId,
					point_id: req.pointId,
					tags: req.tags,
				},
			});
		return response;
	}

	async getTags(): Promise<any> {
		const url = `${SimulationService.base_url}/points/tag`;
		const { data: response } =
			await SimulationService.http.get<any>(url);
		return response;
	}
}
