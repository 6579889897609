import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

import type { TError } from "../../types/common.type";

import { type TAnalysisState } from "../../types/analysis.type";

import {
	restartAnalysisThunk,
	startAnalysisThunk,
} from "./analysis.thunk";

const initialState: TAnalysisState = {
	activeAnalysis: null,
	activeAnalysisData: null,
	selectedDataPoint: null,
	currentAnalysis: {
		isLoading: false,
		data: null,
		status: null,
	},
	activeTapeId: null,
	error: undefined,
	originalTapeData: null,
	isLoading: false,
	tapeUploadStatus: {
		loading: false,
		error: null,
		status: "not-started",
	},
};

/**
 * Redux slice for managing analysis state.
 */
export const analysisState = createSlice({
	name: "analysis",
	initialState,
	reducers: {
		/**
		 * Sets the current analysis data and updates the loading state.
		 * @param state - The current state.
		 * @param action - The action containing the payload.
		 */
		setCurrentAnalysisData: (state, action) => {
			state.currentAnalysis.data = action.payload;
			state.currentAnalysis.status = !action.payload
				? null
				: state.currentAnalysis.status;
		},
		setCurrentAnalysisStatus: (state, action) => {
			state.currentAnalysis.status = action.payload;
		},
		setActiveAnalysisData: (state, action) => {
			state.activeAnalysisData = action.payload;
		},
		setOriginalTapeData: (state, action) => {
			state.originalTapeData = action.payload;
		},
		setSelectedDataPoint: (state, action) => {
			state.selectedDataPoint = action.payload;
		},
		setCurrentAnalysisLoading: (state, action) => {
			state.currentAnalysis.isLoading = action.payload;
		},
		setActiveTapeId: (state, action) => {
			state.activeTapeId = action.payload;
		},
		setTapeUploadStatus: (state, action) => {
			state.tapeUploadStatus = action.payload;
		},
	},
	extraReducers: (builder) => {
		// Extra reducers to handle async actions for starting analysis.
		builder
			.addCase(startAnalysisThunk.pending, (state) => {
				state.currentAnalysis.isLoading = true;
				state.activeAnalysis = null;
				state.tapeUploadStatus.loading = true;
				state.tapeUploadStatus.status = "in-progress";
			})
			.addCase(
				startAnalysisThunk.fulfilled,
				(state, action) => {
					const { tapeId } = action.payload;
					state.activeTapeId = tapeId;
					state.tapeUploadStatus.loading = false;
					state.tapeUploadStatus.status = "success";
				}
			)
			.addCase(
				startAnalysisThunk.rejected,
				(state, action) => {
					state.currentAnalysis.isLoading = false;
					state.activeAnalysis = null;
					state.currentAnalysis.data = null;
					state.isLoading = false;
					state.error = action.payload as TError;
					toast.error((action.payload as TError).msg, {
						autoClose: 10000,
					});
					state.tapeUploadStatus = {
						loading: false,
						error: (action.payload as TError).msg,
						status: "failed",
					};
				}
			);
		builder
			.addCase(restartAnalysisThunk.pending, (state) => {
				state.currentAnalysis.isLoading = true;
				state.activeAnalysis = null;
			})
			.addCase(
				restartAnalysisThunk.fulfilled,
				(state, action) => {
					const tapeId = action.payload;
					state.activeTapeId = tapeId;
				}
			)
			.addCase(
				restartAnalysisThunk.rejected,
				(state, action) => {
					state.currentAnalysis.isLoading = false;
					state.activeAnalysis = null;
					state.currentAnalysis.data = null;
					state.error = action.payload as TError;
					toast.error((action.payload as TError).msg);
				}
			);
	},
});

export const AnalysisActions = analysisState.actions;
export const AnalysisReducer = analysisState.reducer;
