/**
 * Firebase configuration file for the Alloan Project V2.
 *
 * This file contains the Firebase configuration object and initializes the Firebase app.
 * It also exports the Firebase authentication instance.
 *
 * @remarks
 * This code is sourced from the Firebase project settings of Alloan Project V2.
 *
 * @see {@link https://firebase.google.com/docs/web/setup#config-object}
 * @see {@link https://firebase.google.com/docs/auth/web/start}
 * @see {@link https://firebase.google.com/docs/web/learn-more#config-object}
 */

import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

/**
 * Firebase configuration object.
 */
const firebaseConfig = {
	apiKey: "AIzaSyBzDqXAuMInIxj2JB1MKKtUlnkm6WLkSAE",
	authDomain: "alloan-projectv2.firebaseapp.com",
	projectId: "alloan-projectv2",
	storageBucket: "alloan-projectv2.appspot.com",
	messagingSenderId: "426681977711",
	appId: "1:426681977711:web:74a9390e49bdd37ce2b69a",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

/**
 * Firebase authentication instance.
 */
export const auth = getAuth(app);
