import type { AxiosRequestConfig } from "axios";

import { getEndpoint } from "../endpoints";

import {
	httpServiceInstance,
	type HttpService,
} from "./http.service";

export class SanitizationService {
	private static readonly instance: SanitizationService;
	private static http: HttpService;
	private static endpoint: string;
	private static base_url: string;
	constructor() {
		SanitizationService.http = httpServiceInstance;
		SanitizationService.endpoint = getEndpoint();
		SanitizationService.base_url = `${SanitizationService.endpoint}/api/sanitization`;
	}

	async tapeUpload(formData: FormData): Promise<any> {
		const url = `${SanitizationService.base_url}/upload`;
		const config = {
			data: formData,
			headers: {
				"Content-Type": "multipart/form-data",
			},
		};

		const { data } =
			await SanitizationService.http.post<any>(url, config);
		return data;
	}

	async addTapeMetaData(
		data: any,
		tapeId: string
	): Promise<any> {
		const url = `${SanitizationService.base_url}/metadata/${tapeId}`;
		const config = {
			data,
		};

		const { data: response } =
			await SanitizationService.http.post<any>(url, config);
		return response;
	}

	async getDataRange(tapeId: string): Promise<any> {
		const url = `${SanitizationService.base_url}/data_range/${tapeId}`;
		const { data } =
			await SanitizationService.http.get<any>(url);
		return data;
	}

	async updateDataRange(
		data: any,
		tapeId: string
	): Promise<any> {
		const url = `${SanitizationService.base_url}/data_range/${tapeId}`;
		const config = {
			data,
		};

		const { data: response } =
			await SanitizationService.http.post<any>(url, config);
		return response;
	}

	async getColumns(tapeId: string): Promise<any> {
		const url = `${SanitizationService.base_url}/columns/${tapeId}`;

		const { data: response } =
			await SanitizationService.http.get<any>(url);
		return response;
	}

	async updateColumnMapping(
		data: any,
		tapeId: string
	): Promise<any> {
		const url = `${SanitizationService.base_url}/columns/${tapeId}`;
		const config = {
			data,
		};

		const { data: response } =
			await SanitizationService.http.post<any>(url, config);
		return response;
	}

	async getStatistics(tapeId: string): Promise<any> {
		const url = `${SanitizationService.base_url}/stats/${tapeId}`;

		const { data: response } =
			await SanitizationService.http.get<any>(url);
		return response;
	}

	async confirmStatistics(tapeId: string): Promise<any> {
		const url = `${SanitizationService.base_url}/confirm/${tapeId}`;

		const { data: response } =
			await SanitizationService.http.post<any>(url);
		return response;
	}

	async getAllAssetClasses(): Promise<any> {
		const url = `${SanitizationService.base_url}/meta`;

		const { data: response } =
			await SanitizationService.http.get<any>(url);
		return response;
	}

	async getTapeInfo(tapeId: string): Promise<any> {
		const url = `${SanitizationService.base_url}/meta/${tapeId}`;

		const { data: response } =
			await SanitizationService.http.get<any>(url);
		return response;
	}

	async getUserPreferences(): Promise<any> {
		const url = `${SanitizationService.endpoint}/api/user_preferences`;

		const { data: response } =
			await SanitizationService.http.get<any>(url);
		return response;
	}

	async postUserPreferences(data: any): Promise<any> {
		const url = `${SanitizationService.endpoint}/api/user_preferences`;
		const config = {
			data,
		};

		const { data: response } =
			await SanitizationService.http.post<any>(url, config);
		return response;
	}

	async checkForUniqueName(
		tapeName: string,
		abortSignal: AbortSignal
	): Promise<any> {
		const url = `${SanitizationService.endpoint}/api/tapes/does_name_exist`;
		const config = {
			data: {
				tape_name: tapeName,
			},
			signal: abortSignal,
		};

		const { data: response } =
			await SanitizationService.http.post<any>(url, config);
		return response;
	}

	async downloadTape(tapeId: string): Promise<any> {
		const url = `${SanitizationService.endpoint}/api/tapes/download/${tapeId}`;
		const config: AxiosRequestConfig = {
			headers: {
				"Content-Type": "blob",
			},
			responseType: "arraybuffer",
		};

		const { data: response } =
			await SanitizationService.http.get<any>(url, config);
		return response;
	}
}
