import { getEndpoint } from "../endpoints";

import {
	httpServiceInstance,
	type HttpService,
} from "./http.service";

/**
 * Service class for managing tenants.
 */
export class TenantService {
	private static readonly instance: TenantService;
	private static http: HttpService;
	private static endpoint: string;

	/**
	 * Private constructor to prevent instantiation.
	 * Initializes the HttpService instance.
	 */
	constructor() {
		TenantService.http = httpServiceInstance;
		TenantService.endpoint = getEndpoint();
	}

	/**
	 * Retrieves the list of tenants from the API.
	 * @returns A promise that resolves to the list of tenants.
	 */
	async getTenants(): Promise<Record<string, any>> {
		const url = `${TenantService.endpoint}/api/tenants`;
		const config = {};
		try {
			const { data } = await TenantService.http.get<any>(
				url,
				config
			);
			return data;
		} catch (error: any) {
			throw error.response;
		}
	}

	async getAllUsersInTenant(
		tenantId: string
	): Promise<Array<Record<string, any>>> {
		const url = `${TenantService.endpoint}/api/tenants/${tenantId}`;
		const config = {};
		try {
			const { data } = await TenantService.http.get<any>(
				url,
				config
			);
			return data;
		} catch (error: any) {
			throw error.response;
		}
	}
}
