/**
 * Renders the navigation component.
 * This component displays the navigation bar with brand, links, user avatar, and logout button.
 */
import { Option, Select } from "@mui/joy";
import {
	Avatar,
	Box,
	Divider,
	Menu,
	MenuItem,
	Tooltip,
} from "@mui/material";
import { IoIosArrowDown } from "@react-icons/all-files/io/IoIosArrowDown";
import { signOut } from "firebase/auth";
import {
	useCallback,
	useEffect,
	useMemo,
	useState,
} from "react";
import {
	Link,
	useLocation,
	useNavigate,
} from "react-router-dom";

import logo from "../assets/logos/blackblueLogo.svg";
import { auth } from "../firebase/firebaseConfig";
import {
	useAppDispatch,
	useAppSelector,
} from "../redux/store";
import { TenantActions } from "../redux/tenants/tenant.slice";
import { UserAuthActions } from "../redux/user/user.slice";

/**
 * Navigation component.
 */
export const Navigation = () => {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const location = useLocation();

	// Get user authentication status and information from Redux store
	const { user } = useAppSelector((state) => state.user);
	const { allTenants, activeTenant } = useAppSelector(
		(state) => state.tenant
	);
	const [tenantList, setTenantList] = useState<
		Array<string>
	>([]);

	useEffect(() => {
		if (allTenants) {
			const temp = Object.keys(allTenants);
			setTenantList(temp);
		}
	}, [allTenants]);

	/**
	 * Handles the logout action.
	 * Signs out the user, dispatches logout action, and navigates to the login page.
	 */
	const handleLogout = useCallback(() => {
		signOut(auth)
			.then(() => {
				// Sign-out successful.
				dispatch(UserAuthActions.logout());
				navigate("/login");
			})
			.catch((error) => {
				console.error(error);
			});
	}, [dispatch, navigate]);

	const [anchorEl, setAnchorEl] =
		useState<null | HTMLElement>(null);
	const open = Boolean(anchorEl);
	const handleClick = (
		event: React.MouseEvent<HTMLElement>
	) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};
	const handleChange = (
		event: React.SyntheticEvent | null,
		newValue: string | null
	) => {
		dispatch(
			TenantActions.setActiveTenantReducer(
				newValue as string
			)
		);
		navigate("/");
	};

	const allPaths = useMemo(() => {
		return [
			{ path: "/", name: "Home" },
			{ path: "/library", name: "Tape Uploads" },
		];
	}, []);

	return (
		<Box className="navigation">
			<Box className="brand">
				<img
					className="logo"
					src={logo}
					alt="Alloan"
					onClick={() => {
						navigate("/");
					}}
				/>
				<Box className="nav-links">
					{allPaths.map((path) => (
						<Link
							key={path.path}
							className={`link ${
								location.pathname === path.path
									? "active"
									: ""
							}`}
							to={path.path}
						>
							{path.name}
						</Link>
					))}
				</Box>
			</Box>
			<Box className="actions">
				{tenantList.length > 1 ? (
					<Select
						className="tenant-dropdown"
						indicator={<IoIosArrowDown />}
						value={activeTenant}
						onChange={handleChange}
						disabled={tenantList.length <= 1}
					>
						{tenantList.map((tenant) => (
							<Option
								key={tenant}
								value={tenant}
								className="tenant-option"
							>
								{tenant}
							</Option>
						))}
					</Select>
				) : null}
				<Tooltip
					title="Account settings"
					sx={{
						cursor: "pointer",
					}}
				>
					<Box
						className={"avatar-box"}
						onClick={handleClick}
					>
						<Avatar
							sx={{
								width: 32,
								height: 32,
								background: "#4D2ED3",
								color: "white",
							}}
						>
							{user?.name.charAt(0)}
						</Avatar>
					</Box>
				</Tooltip>
				<Menu
					anchorEl={anchorEl}
					id="account-menu"
					open={open}
					onClose={handleClose}
					onClick={handleClose}
					transformOrigin={{
						horizontal: "right",
						vertical: "top",
					}}
					anchorOrigin={{
						horizontal: "right",
						vertical: "bottom",
					}}
				>
					<MenuItem
						onClick={handleClose}
						className={"user-meta"}
						disabled={true}
					>
						<Avatar
							sx={{
								width: 36,
								height: 36,
								background: "#4D2ED3",
								color: "white",
							}}
						>
							{user?.name.charAt(0)}
						</Avatar>
						<Box className={"content"}>
							<h6>{user?.name}</h6>
							<p>{user?.email_id}</p>
						</Box>
					</MenuItem>
					<Divider />
					<MenuItem onClick={handleLogout}>Logout</MenuItem>
				</Menu>

				{/* <Button
					color="primary"
					endDecorator={<TbLogout />}
					onClick={handleLogout}
				>
					Logout
				</Button> */}
			</Box>
		</Box>
	);
};
