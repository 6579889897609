export type TSimulationSlice = {
	tapeSimulationInfo: any;
	pageShouldLoad: boolean;
	savedSimulations: Array<any>;
	defaultRunId: string;
	latestRunId: string;
	untitledRunId: string;
	rawPointsData: {
		loading: boolean;
		data: Array<any>;
		status?: string | null;
		error: any;
	} | null;
	simulationAxisData: Record<string, Array<any>>;
	selectedPoint: Record<string, any> | null;
	activeLabels: {
		x: string | null;
		y: string | null;
		z: string | null;
	};
	valuationQuestions: {
		questions: Array<TSimulationQuestions>;
		loading: boolean;
		error: any;
	};
	simulationMeta?: {
		runId: string;
		runName: string;
		loading: boolean;
		error: any;
	};
	filterParameters: Array<Record<string, any>> | null;
	tagList: any;
};

export type TSimulationPointsRequestObject = {
	tapeId: string;
	filterObject: any;
};

export type TSimulationQuestionRequestObject = {
	tapeId?: string;
	runId?: string | undefined;
	isNewRunRequested: boolean;
};

export type TSimulationQuestions = {
	question: string;
	answers: Array<{
		id: string;
		text: string;
	}>;
	answerType: string;
	answer: string | null;
	defaultAnswer: string | null;
	key: string;
	validations?: any;
	isGenAIEnabled: boolean;
	pythonValue: string;
	naturalTextValue: string;
};

export type TSimulationAssumptionRequestObject = {
	questionId: string;
	userInput: string;
	runId: string;
	userInputType:
		| "natural_language"
		| "python_code"
		| "option_selection";
};

export type TCreateSimulationRequestObject = {
	runId: string;
	tapeId: string;
};

export type TSaveSimulationRequestObject = {
	runId: string;
	tapeId: string;
	runName: string;
};

export type TFilterRequestObject = {
	tapeId?: string;
	runId?: string;
};

export type TGetTagRequestObject = {
	tapeId: string;
	pointId: string;
	tags: Array<string>;
};

export enum SimulationEnum {
	"CREATE" = "createSimulation",
	"VIEW" = "viewSimulation",
	"EDIT" = "editSimulation",
}
