import { Step } from "@mui/material";
import { nanoid } from "@reduxjs/toolkit";

import { StepEnum } from "../pages/SanitizeTape";
import { AnalysisStageEnum } from "../types/analysis.type";

export const formatTapeData = (data: any) => {
	const id = data.tapeId || nanoid();
	const name = data.name || "Untitled";
	const file = data.file || null;
	const sasLink = data.sasLink || null;
	const {
		keyOutputs,
		assumptions,
		loanTapeStats,
		finalOutput,
	} = data.analysisData || {};
	const status = data.status || AnalysisStageEnum.UPLOADED;
	return {
		id,
		name,
		file,
		sasLink,
		status,
		analysisData: {
			keyOutputs,
			assumptions,
			loanTapeStats,
			finalOutput,
		},
	};
};

export const extractKeyValues = (
	data: Record<string, any>
) => {
	const keyValues = Object.entries(data).map(
		([key, value]) => {
			return { key, value };
		}
	);
	return keyValues;
};

export const statusOptions = [
	{
		label: "Any Status",
		value: "any",
		backgroundColor: "#FFFFFF",
		fontColor: "#000000",
	},
	{
		label: "Pricing Failed",
		value: "PRICING_FAILED",
		backgroundColor: "#FEF3F2",
		fontColor: "#D92D20",
	},
	{
		label: "Sanitization Pending",
		value: "pending",
		backgroundColor: "#EFF8FF",
		fontColor: "#2E90FA",
	},
	{
		label: "Sanitization Pending",
		value: "SANITIZATION_PENDING",
		backgroundColor: "#EFF8FF",
		fontColor: "#2E90FA",
	},
	{
		label: "Sanitization Completed",
		value: "SANITIZATION_COMPLETED",
		backgroundColor: "#CCFBEF",
		fontColor: "#0E9384",
	},
	{
		label: "Pricing In Progress",
		value: "PRICING_IN_PROGRESS",
		backgroundColor: "#F4F3FF",
		fontColor: "#6938EF",
	},
	{
		label: "Pricing Completed",
		value: "PRICING_COMPLETED",
		backgroundColor: "#EDFCF2",
		fontColor: "#16B364",
	},
	{
		label: "Sanitization Failed",
		value: "SANITIZATION_FAILED",
		backgroundColor: "#FEF3F2",
		fontColor: "#D92D20",
	},
];

export const getStatus = (status: string) => {
	return (
		statusOptions.find(
			(option) => option.value === status
		) ?? {
			label: "Pending",
			value: "pending",
			backgroundColor: "#EFF8FF",
			fontColor: "#2E90FA",
		}
	);
};

export const getSanitizationSteps = (status: string) => {
	switch (status) {
		case "GET_DATA_RANGE":
			return {
				step: status,
				path: StepEnum.Extraction,
				nextStep: StepEnum.Mapping,
				name: "Extract Tape",
			};
		case "GET_COLUMNS":
			return {
				step: status,
				path: StepEnum.Mapping,
				nextStep: StepEnum.Statistics,
				name: "Column Mapping",
			};
		case "REVIEW_STATS":
			return {
				step: status,
				path: StepEnum.Statistics,
				nextStep: StepEnum.Analysis,
				name: "Review Statistics",
			};
		case "SANITIZATION_COMPLETE":
			return {
				step: status,
				path: StepEnum.Analysis,
				nextStep: null,
				name: "Sanitization Complete",
			};
		default:
			return {
				step: status,
				path: StepEnum.Analysis,
				nextStep: null,
				name: "Sanitization Complete",
			};
	}
};
