import { getEndpoint } from "../endpoints";

import {
	httpServiceInstance,
	type HttpService,
} from "./http.service";

/**
 * The UserService class provides methods for interacting with the user API.
 */
export class UserService {
	private static http: HttpService;
	private static endpoint: string;

	constructor() {
		// Private constructor to prevent instantiation
		UserService.http = httpServiceInstance;
		UserService.endpoint = getEndpoint();
	}

	async getUserMeta() {
		const url = `${UserService.endpoint}/api/user_meta`;
		try {
			const { data } =
				await UserService.http.get<Record<string, any>>(
					url
				);
			return data;
		} catch (error: any) {
			console.error(error);
			throw new Error(error.response);
		}
	}

	async changePassword(password: string) {
		const url = `${UserService.endpoint}/api/users/change_password`;
		const config = {
			data: {
				new_password: password,
			},
		};
		try {
			const { data, status } = await UserService.http.post(
				url,
				config
			);
			return {
				success: status === 200,
				data,
			};
		} catch (error: any) {
			console.error(error);
			return {
				success: false,
				msg: error.response,
			};
		}
	}
}
