import { createAsyncThunk } from "@reduxjs/toolkit";

import { services } from "../../services";
import { formatTapeData } from "../../utils/tapeUtils";
import { TapeSliceActions } from "../tapes/tapes.slice";

/**
 * Thunk action creator for starting the analysis.
 * This thunk dispatches an asynchronous action to start the analysis process.
 *
 * @param formData - The form data to be used for analysis.
 * @returns A promise that resolves to the analysis result data or rejects with an error.
 */
export const startAnalysisThunk = createAsyncThunk(
	"analysis/startAnalysis",
	async (file: File, { dispatch, rejectWithValue }) => {
		try {
			const formData = new FormData();
			formData.append("file", file);
			const data =
				await services.analysisServiceInstance.startAnalysis(
					formData
				);
			const temp = {
				name: file.name,
				// file: formData.get("file") as File,
				tapeId: data.status_response.tape_id,
			};
			const tape = formatTapeData(temp);
			dispatch(TapeSliceActions.addTape(tape));
			return {
				tapeId: tape.id,
				sasLink: tape.sasLink,
			};
		} catch (error: any) {
			console.error("redux error", error);
			return rejectWithValue({
				...error.response.data,
				msg: `Error occurred: ${error.response.data.error_code} - ${error.response.data.msg}`,
			});
		}
	}
);

export const restartAnalysisThunk = createAsyncThunk(
	"analysis/restartAnalysis",
	async (tapeId: string, { dispatch, rejectWithValue }) => {
		try {
			const data =
				await services.analysisServiceInstance.restartAnalysis(
					tapeId
				);
			return tapeId;
		} catch (error: any) {
			console.error("redux error", error);
			return rejectWithValue(error.response.data);
		}
	}
);

/**
 * This is a thunk action creator for polling the analysis status.
 * @deprecated This thunk is deprecated. We are using direct polling from the component.
 */
export const pollingAnalysisThunk = createAsyncThunk(
	"analysis/pollingAnalysis",
	async (url: string) => {
		try {
			const data =
				await services.analysisServiceInstance.pollingAnalysis(
					url
				);
			return data;
		} catch (error: any) {
			// You can handle errors here and return a rejected action
			return error;
		}
	}
);
