import { createSlice } from "@reduxjs/toolkit";
import { multiFactor, signOut } from "firebase/auth";
import { jwtDecode } from "jwt-decode";
import { toast } from "react-toastify";

import { auth } from "../../firebase/firebaseConfig";
import { type TUserAuthState } from "../../types/user.type";

import {
	passwordUpdatedThunk,
	userLoginThunk,
} from "./user.thunk";

// This file defines the Redux slice for user authentication.
// It includes the initial state, action creators, and reducers.
// The slice handles various logics related to user authentication.

// Define the initial state for user authentication
const initialState: TUserAuthState = {
	token: null,
	decodedToken: null,
	usernameFromToken: null,
	authStatus: {
		isLoggedIn: false,
		isLoading: false,
	},
	isPasswordSet: false,
	isMultiFactorAuthSet: false,
	loading: false,
	user: null,
	mfaError: null,
	isGlobalAdmin: null,
};

// Create the userAuthSlice using createSlice function from Redux Toolkit
export const userAuthSlice = createSlice({
	name: "auth",
	initialState,
	reducers: {
		// Reducer to set the authentication token
		setAuthenticated: (state, action) => {
			state.token = action.payload;
			const decoded: any = jwtDecode(
				action.payload as string
			);
			state.decodedToken = decoded;
			state.usernameFromToken = decoded.name;
			state.isPasswordSet = decoded.email_verified;
		},
		// Reducer to set the loading state of authentication
		setAuthLoading: (state, action) => {
			state.authStatus.isLoading = action.payload;
		},
		setMfaError: (state, action) => {
			state.mfaError = action.payload;
			const user = auth.currentUser;
			if (user) {
				state.isMultiFactorAuthSet =
					multiFactor(user).enrolledFactors.length > 0;
			} else {
				state.isMultiFactorAuthSet = !!state.mfaError;
			}
		},
		// Reducer to handle user logout
		logout: (state) => {
			state.token = null;
			state.decodedToken = null;
			state.usernameFromToken = null;
			state.authStatus.isLoggedIn = false;
			state.authStatus.isLoading = false;
			state.isPasswordSet = false;
			state.isMultiFactorAuthSet = false;
			state.loading = false;
			state.user = null;
			state.mfaError = null;
			state.isGlobalAdmin = null;
			localStorage.clear();
		},
		updateGlobalAdmin: (state, action) => {
			const activeTenant = action.payload;
			if (state.user?.tenants) {
				const allTenants = state.user?.tenants;
				const tenant = allTenants[activeTenant];
				if (
					state.user.global_role === "global_admin" ||
					state.user.global_role === "global_tenant_admin"
				) {
					state.isGlobalAdmin = true;
				} else {
					if (tenant.role === "tenant_admin") {
						state.isGlobalAdmin = true;
					} else {
						state.isGlobalAdmin = false;
					}
				}
			}
		},
	},
	extraReducers: (builder) => {
		// Extra reducers to handle async actions for user login
		builder
			.addCase(userLoginThunk.pending, (state) => {
				state.authStatus.isLoading = true;
			})
			.addCase(
				userLoginThunk.fulfilled,
				(state, action) => {
					const meta = action.payload;
					state.user = meta;
					if (
						meta.global_role === "global_admin" ||
						meta.global_role === "global_tenant_admin"
					) {
						state.isGlobalAdmin = true;
					} else {
						state.isGlobalAdmin = null;
					}
					state.authStatus.isLoggedIn = true;
					state.isPasswordSet = meta.isPasswordSet;
					state.authStatus.isLoading = false;
				}
			)
			.addCase(userLoginThunk.rejected, (state) => {
				state.authStatus.isLoading = false;
				toast.error("Sign in failed, please try again.");
				void signOut(auth).then(() => {
					window.location.reload();
				});
			});

		// Extra reducers to handle async actions for password update
		builder
			.addCase(passwordUpdatedThunk.pending, (state) => {
				state.loading = true;
			})
			.addCase(
				passwordUpdatedThunk.fulfilled,
				(state, action) => {
					if (action.payload.success) {
						// state.isPasswordSet = true;
						toast.success("Password updated successfully");
						void signOut(auth).then(() => {
							window.location.reload();
						});
					} else {
						state.isPasswordSet = false;
						toast.error("Password update failed");
					}
					state.loading = false;
					// state.isPasswordSet = true;
				}
			)
			.addCase(passwordUpdatedThunk.rejected, (state) => {
				state.loading = false;
			});
	},
});

// Export the action creators and reducer from the userAuthSlice
export const UserAuthActions = userAuthSlice.actions;
export const UserAuthReducer = userAuthSlice.reducer;
