import { createSlice } from "@reduxjs/toolkit";
import { isEqual } from "lodash";
import { toast } from "react-toastify";

import type { TSanitizationState } from "../../types/sanitization.type";

import { type StepEnum } from "../../pages/SanitizeTape";
import { getSanitizationSteps } from "../../utils/tapeUtils";

import {
	getColumnMappingDataThunk,
	getDataFromDataRange,
	getDataRangeThunk,
	getPreferencesThunk,
	getStatistics,
	getTapeInfo,
	updateColumnMappingDataThunk,
	uploadTapeThunk,
} from "./sanitization.thunk";

export const initialState: TSanitizationState = {
	activeTapeIdForSanitization: null,
	isLoading: false,
	error: null,
	preferences: {
		showDataRangeCoachMark: false,
		showColumnMappingCoachMark: false,
	},
	tapeUploadStatus: {
		status: "not-started",
		error: null,
		loading: false,
	},
	tapeSanitizationInfoStatus: {
		loading: false,
		error: null,
	},
	tapeSanitizationInfo: null,
	tapeExtractionStatus: {
		loading: false,
		error: null,
		shortPreviewLoading: false,
	},
	tapeExtraction: null,
	columnMappingStatus: {
		loading: false,
		error: null,
		previewLoading: false,
	},
	columnMapping: null,
	reviewStatisticsStatus: {
		loading: false,
		error: null,
	},
	reviewStatistics: null,
	nextStep: null,
	submissionStatus: null,
	tapeDownloadData: null,
};

export const sanitizationState = createSlice({
	name: "sanitization",
	initialState,
	reducers: {
		resetEverything: (state) => {
			state = initialState;
		},

		updateTapeUploadStatus: (state, action) => {
			state.tapeUploadStatus = action.payload;
		},

		updateRangeSelection: (state, action) => {
			const { sheet, headerRange, dataRange } =
				action.payload;
			if (state.tapeExtraction) {
				state.tapeExtraction.recentRangeSelection = {
					sheet,
					headerRange,
					dataRange,
				};
			}
		},
		updateCurrentStep: (state, action) => {
			if (state.tapeSanitizationInfo) {
				const currentStepIndex =
					action.payload.all_steps.findIndex(
						(el: string) =>
							el === action.payload.current_step
					);
				state.tapeSanitizationInfo = {
					allSteps:
						action.payload.all_steps.map(
							(el: string, index: number) => {
								const step = getSanitizationSteps(el);
								return {
									...step,
									isCompleted: currentStepIndex > index,
								};
							}
						) ?? [],
					currentStep: {
						...getSanitizationSteps(
							action.payload.current_step as string
						),
						isCompleted: false,
					},
					tapeName: action.payload.tape_name,
				};
				state.submissionStatus = {
					currentStep:
						state.tapeSanitizationInfo.currentStep.path,
					nextStep: state.tapeSanitizationInfo.currentStep
						.nextStep as StepEnum,
					submissionCreated: false,
					isSubmissionSuccessful: false,
					error: null,
				};
			}
		},
		updateSanitizationSubmissionStatus: (state, action) => {
			state.submissionStatus = action.payload;
		},
		updateColumnMappingData: (state, action) => {
			state.columnMapping = {
				allColumns: action.payload,
			};
		},
		updatePreferences: (state, action) => {
			state.preferences = action.payload;
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(uploadTapeThunk.pending, (state) => {
				state.tapeUploadStatus.status = "in-progress";
				state.tapeUploadStatus.loading = true;
				state.error = null;
			})
			.addCase(
				uploadTapeThunk.fulfilled,
				(state, action) => {
					const payload = action.payload;
					state.tapeUploadStatus.status = "completed";
					state.tapeUploadStatus.loading = false;
					state.activeTapeIdForSanitization =
						payload.tape_id;
					state.error = null;

					const allSheets = Object.keys(
						payload.sheet_names
					);
					const previewRange = payload.preview_data_range;
					const selectedSheet = previewRange.sheet_name;
					state.tapeExtraction = {
						sheets: allSheets,
						selectedSheet,
						allSheetsData: Object.entries(
							payload.sheet_names
						).map(([key, value]: any) => {
							return {
								sheetName: key,
								meta: value,
							};
						}),
						suggestedRange: null,
					};
					state.nextStep = payload.next_step;
				}
			)
			.addCase(
				uploadTapeThunk.rejected,
				(state, action) => {
					state.tapeUploadStatus.loading = false;
					state.tapeUploadStatus.status = "failed";
					state.error = action.payload as any;
				}
			);

		builder
			.addCase(getDataRangeThunk.pending, (state) => {
				state.error = null;
				state.tapeExtractionStatus.loading = true;
				state.tapeExtractionStatus.shortPreviewLoading =
					true;
			})
			.addCase(
				getDataRangeThunk.fulfilled,
				(state, action) => {
					state.error = null;

					const payload = action.payload;

					const preview = action.payload.preview_data_range;

					const suggestion = {
						sheetName: preview.sheet_name,
						headerRange: preview.header_range,
						bodyRange: preview.body_range,
					};

					const allSheets = Object.keys(
						payload.sheet_bounds
					);
					const selectedSheet = suggestion.sheetName;

					const confirmData = payload.confirmed_data_range;
					const isPreviouslyConfirmed =
						Boolean(confirmData);
					const isModifiedAndConfirmed = isEqual(
						confirmData,
						preview
					);

					state.tapeExtraction = {
						sheets: allSheets,
						selectedSheet,
						allSheetsData: Object.entries(
							payload.sheet_bounds
						).map(([key, value]: any) => {
							return {
								sheetName: key,
								meta: value,
							};
						}),
						suggestedRange: suggestion,
						recentRangeSelection: {
							sheet: suggestion.sheetName,
							headerRange: suggestion.headerRange,
							dataRange: suggestion.bodyRange,
						},
						isStepConfirmed: isPreviouslyConfirmed,
						isModifiedNotConfirmed: !isModifiedAndConfirmed,
					};
					state.tapeExtractionStatus.loading = false;
					state.tapeExtractionStatus.shortPreviewLoading =
						false;
				}
			)
			.addCase(
				getDataRangeThunk.rejected,
				(state, action) => {
					state.error = action.payload as string;
					state.tapeExtraction = null;
					state.tapeExtractionStatus.loading = false;
					state.tapeExtractionStatus.error =
						action.payload as string;
					state.tapeExtractionStatus.shortPreviewLoading =
						false;
				}
			);
		builder
			.addCase(getDataFromDataRange.pending, (state) => {
				state.tapeExtractionStatus.loading = true;
				state.error = null;
				state.tapeExtractionStatus.shortPreviewLoading =
					true;
				state.tapeExtractionStatus.error = null;
				state.tapeExtractionStatus.statusCode = undefined;
			})
			.addCase(
				getDataFromDataRange.fulfilled,
				(state, action) => {
					const payload = action.payload;
					state.tapeExtractionStatus.loading = false;
					state.error = null;
					if (state.tapeExtraction && payload.table) {
						state.tapeExtraction.shortPreview =
							payload.table;
					}
					state.tapeExtractionStatus.shortPreviewLoading =
						false;
					state.tapeExtractionStatus.error = null;
					state.tapeExtractionStatus.statusCode = undefined;
				}
			)
			.addCase(
				getDataFromDataRange.rejected,
				(state, action) => {
					state.tapeExtractionStatus.loading = false;
					state.error = action.payload as string;
					state.tapeExtractionStatus.shortPreviewLoading =
						false;
					state.tapeExtractionStatus.error = (
						action.payload as any
					)?.msg as string;

					state.tapeExtractionStatus.statusCode = (
						action.payload as any
					).status;
					if (state.tapeExtraction) {
						state.tapeExtraction.shortPreview = undefined;
					}
				}
			);
		builder
			.addCase(
				getColumnMappingDataThunk.pending,
				(state) => {
					state.columnMappingStatus.loading = true;
					state.error = null;
				}
			)
			.addCase(
				getColumnMappingDataThunk.fulfilled,
				(state, action) => {
					state.columnMappingStatus.loading = false;
					state.error = null;
					if (action.payload) {
						state.columnMapping = {
							allColumns: action.payload,
						};
					}
				}
			)
			.addCase(
				getColumnMappingDataThunk.rejected,
				(state, action) => {
					state.columnMappingStatus.loading = false;
					state.error = action.payload as string;
				}
			);
		builder
			.addCase(
				updateColumnMappingDataThunk.pending,
				(state) => {
					state.error = null;
					state.columnMappingStatus.previewLoading = true;
				}
			)
			.addCase(
				updateColumnMappingDataThunk.fulfilled,
				(state, action) => {
					state.error = null;
					if (!action.payload.isStepConfirmed) {
						state.columnMapping = {
							allColumns: action.payload.data,
						};
					}
					state.columnMappingStatus.previewLoading = false;
				}
			)
			.addCase(
				updateColumnMappingDataThunk.rejected,
				(state, action) => {
					state.columnMappingStatus.previewLoading = false;
					state.error = action.payload as string;
				}
			);
		builder
			.addCase(getStatistics.pending, (state) => {
				state.reviewStatisticsStatus.loading = true;
				state.error = null;
			})
			.addCase(getStatistics.fulfilled, (state, action) => {
				state.reviewStatisticsStatus.loading = false;
				state.error = null;
				if (action.payload) {
					state.reviewStatistics = {
						allStats: action.payload,
					};
				}
			})
			.addCase(getStatistics.rejected, (state, action) => {
				state.reviewStatisticsStatus.loading = false;
				state.error = action.payload as string;
				state.reviewStatisticsStatus.error =
					action.payload as any;
				toast.error("Error occurred while fetching stats.");
			});
		builder
			.addCase(getTapeInfo.pending, (state) => {
				state.tapeSanitizationInfoStatus.loading = true;
			})
			.addCase(getTapeInfo.fulfilled, (state, action) => {
				if (
					action.payload.is_version_supported &&
					action.payload.all_steps &&
					action.payload.current_step
				) {
					const currentStepIndex =
						action.payload.all_steps.findIndex(
							(el: string) =>
								el === action.payload.current_step
						);
					state.tapeSanitizationInfo = {
						allSteps:
							action.payload.all_steps.map(
								(el: string, index: number) => {
									const step = getSanitizationSteps(el);
									return {
										...step,
										isCompleted: currentStepIndex > index,
									};
								}
							) ?? [],
						currentStep: {
							...getSanitizationSteps(
								action.payload.current_step as string
							),
							isCompleted: false,
						},
						tapeName: action.payload.tape_name,
					};
				} else if (
					action.payload.is_version_supported === false
				) {
					state.tapeSanitizationInfo = {
						allSteps: [],
						currentStep: {
							...getSanitizationSteps(
								"SANITIZATION_COMPLETE"
							),
							isCompleted: true,
						},
						tapeName: "",
					};
				}
				state.tapeSanitizationInfoStatus.loading = false;
			})
			.addCase(getTapeInfo.rejected, (state, action) => {
				state.tapeSanitizationInfoStatus.loading = false;
			});
		builder.addCase(
			getPreferencesThunk.pending,
			(state) => {
				state.error = null;
			}
		);
		builder.addCase(
			getPreferencesThunk.fulfilled,
			(state, action) => {
				const payload = action.payload;
				state.preferences = {
					...state.preferences,
					showDataRangeCoachMark: Boolean(
						payload.ui.show_sanitization_tutorial
					),
				};
			}
		);
		builder.addCase(
			getPreferencesThunk.rejected,
			(state, action) => {
				state.error = action.payload as string;
				state.preferences = {
					showDataRangeCoachMark: true,
					showColumnMappingCoachMark: true,
				};
			}
		);
	},
});

export const SanitizationActions =
	sanitizationState.actions;
export const SanitizationReducer =
	sanitizationState.reducer;
