import type { TSimulationQuestions } from "../types/simulation.type";

export const extractSimulations = (
	data: any,
	defaultRunId: string
) => {
	if (data) {
		const res = Object.entries(data).map(
			([key, value]: Array<any>) => {
				if (key !== defaultRunId)
					return {
						id: key,
						...value,
					};
				else {
					return {
						id: key,
						...value,
						run_name: "Alloan Default Run",
					};
				}
			}
		);
		return res;
	} else {
		return [];
	}
};

export const extractSimulationAxisData = (
	data: Array<any>
) => {
	const result: any = {
		price: [],
	};
	data.forEach((element) => {
		if (
			element.assumptions &&
			typeof element.assumptions === "object"
		) {
			Object.entries(element.assumptions).forEach(
				([key, value]) => {
					if (result[key]) {
						result[key].push(value);
					} else {
						result[key] = [value];
					}
				}
			);
		}
		result.price.push(element.price);
	});

	return result;
};

export const extractQuestions = (
	data: Record<string, any>
) => {
	const res: Array<TSimulationQuestions> = Object.entries(
		data
	).map(([key, value]) => {
		return {
			question: value.question,
			answers:
				value.answer_type_info.answer_type ===
				"option_selection"
					? Object.entries(
							value.answer_type_info.options
						).map(([key, option]: any) => ({
							id: key,
							text: option.display_name,
						}))
					: [],
			answerType: value.answer_type_info.answer_type,
			defaultAnswer: value.default_value,
			key,
			validations:
				value.answer_type_info.validation_variables,
			isGenAIEnabled: value.is_genai_compatible,
			answer: value.answer,
			pythonValue: value.python_transformation_code,
			naturalTextValue: value.transform_description,
		};
	});
	const whichModelQuestion = res.find(
		(question) => question.key === "which_model"
	);
	if (whichModelQuestion) {
		res.splice(res.indexOf(whichModelQuestion), 1);
		res.unshift(whichModelQuestion);
	}
	return res;
};

/**
	
	{
  "is_run_confirmed": false,
  "is_user_saved_run": false,
  "model_id": "none",
  "questions": {
    "which_model": {
      "answer_type_info": {
        "answer_type": "option_selection",
        "options": {
          "alloan": {
            "display_name": "Alloan"
          },
          "alloan_advanced": {
            "display_name": "Alloan Advanced"
          },
          "contract_cashflows": {
            "display_name": "Contract Cashflows"
          },
          "rating_agency": {
            "display_name": "Rating Agency"
          },
          "roll_rates": {
            "display_name": "Roll Rates"
          },
          "street": {
            "display_name": "Street"
          }
        }
      },
      "default_value": null,
      "is_genai_compatible": false,
      "question": "What models would you like to run?"
    }
  },
  "run_id": "e4091646-08fc-4529-a8cb-f10f2f4719af",
  "run_name": "untitled-run"
}

	
	
	*/
