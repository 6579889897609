/**
 * Renders the layout component that wraps the entire application.
 * It includes a navigation bar and a container for rendering the main content.
 * The main content is rendered using the React Router's Outlet component.
 */
import { Box } from "@mui/material";
import { useMemo } from "react";
import { Outlet, useLocation } from "react-router-dom";

import { Navigation } from "./Navigation";

export const Layout = () => {
	const location = useLocation();
	const { pathname } = location;
	const showNavigation = useMemo(() => {
		if (pathname.includes("/sanitize/")) return false;
		else if (pathname.includes("/simulation")) return false;
		else return true;
	}, [pathname]);

	return (
		<Box className="main-layout">
			{showNavigation ? <Navigation /> : null}
			<Box
				className="container"
				style={{
					paddingTop: showNavigation ? "60px" : "0",
				}}
			>
				<Box className="page-layout">
					<Outlet />
				</Box>
			</Box>
		</Box>
	);
};
