import { Button } from "@mui/joy";
import Accordion, {
	accordionClasses,
} from "@mui/joy/Accordion";
import AccordionDetails from "@mui/joy/AccordionDetails";
import AccordionGroup from "@mui/joy/AccordionGroup";
import AccordionSummary from "@mui/joy/AccordionSummary";
import { Box } from "@mui/material";
import { useCallback, useMemo } from "react";

import errorIcon from "./assets/svg/cloud-remove.svg";
import { useAppSelector } from "./redux/store";
import text from "./textContent/text.json";
export const Fallback = ({
	error,
	resetErrorBoundary,
}: any) => {
	const textContent = useMemo(() => text.errorScreen, []);

	const { activeTapeId } = useAppSelector(
		(state) => state.analysis
	);

	const handleGoHome = useCallback(() => {
		window.location.href = "/";
	}, []);

	const handleSendEmail = useCallback(() => {
		const emailId = "contact@alloan.ai";
		const subject =
			"Regarding the error in the application";
		const body = `Here is the error log: \n ${error.message ?? error.msg} \n Tape Id: ${activeTapeId} \n correlation_id: ${error.correlation_id} \n`;

		const mailtoLink = `mailto:${emailId}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
		window.location.href = mailtoLink;
	}, [error, activeTapeId]);

	return (
		<Box className={"error-boundary-page"}>
			<Box className={"error-icon"}>
				<img
					src={errorIcon}
					alt="error"
				/>
			</Box>
			<h3>{textContent.heading}</h3>
			<p>{error.message}</p>
			<p>{textContent.description}</p>

			<AccordionGroup
				sx={{
					maxWidth: 500,
					maxHeight: 400,
					[`& .${accordionClasses.root}`]: {
						marginTop: "0.5rem",
						transition: "0.2s ease",
						'& button:not([aria-expanded="true"])': {
							transition: "0.2s ease",
							paddingBottom: "0.625rem",
						},
						"& button:hover": {
							background: "transparent",
						},
					},
					[`& .${accordionClasses.root}.${accordionClasses.expanded}`]:
						{
							bgcolor: "background.level1",
							borderRadius: "md",
							borderBottom: "1px solid",
							borderColor: "background.level2",
						},
					'& [aria-expanded="true"]': {
						boxShadow: (theme) =>
							`inset 0 -1px 0 ${theme.vars.palette.divider}`,
					},
				}}
			>
				<Accordion>
					<AccordionSummary>
						View Error Log
					</AccordionSummary>
					<AccordionDetails className={"error-log-details"}>
						<p>Tape Id : {activeTapeId}</p>
						<pre style={{ color: "red" }}>
							{error.stack}
						</pre>
					</AccordionDetails>
				</Accordion>
			</AccordionGroup>
			<Box
				className="actions"
				sx={{
					display: "flex",
					justifyContent: "center",
					marginTop: "1rem",
					gap: "1rem",
				}}
			>
				<Button onClick={handleGoHome}>Go Home</Button>
				<Button onClick={handleSendEmail}>
					Get Support
				</Button>
			</Box>
		</Box>
	);
};
