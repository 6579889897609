import { Suspense, lazy } from "react";
import { Navigate, Route, Routes } from "react-router-dom";

import { AuthenticationCheck } from "./AuthenticationCheck";
import { SanitizationContextProvider } from "./contexts/sanitizationContext";
import { SimulationContextProvider } from "./contexts/simulationContext";
import { PasswordSetCheck } from "./PasswordSetCheck";
import { useAppSelector } from "./redux/store";
import { LoaderContainer } from "./shared/Loader";

const Login = lazy(
	async () => await import("./pages/Login")
);
const MultiFactor = lazy(
	async () => await import("./pages/MultiFactor")
);
const SanitizeTape = lazy(
	async () => await import("./pages/SanitizeTape")
);
const CreatePassword = lazy(
	async () => await import("./pages/CreatePassword")
);
const LandingPage = lazy(
	async () => await import("./pages/LandingPage")
);

const TapeSearch = lazy(
	async () => await import("./pages/TapeSearch")
);

const AnalysisPage = lazy(
	async () => await import("./pages/Analysis")
);

const Simulation = lazy(
	async () => await import("./pages/Simulation")
);

export const AppRouter = () => {
	const { authStatus, isPasswordSet } = useAppSelector(
		(state) => state.user
	);

	return (
		<Routes>
			<Route
				path="/"
				element={<AuthenticationCheck />}
			>
				<Route
					path="/"
					element={<PasswordSetCheck />}
				>
					<Route
						path="/analysis"
						element={
							<Suspense
								fallback={<LoaderContainer width={50} />}
							>
								<AnalysisPage />
							</Suspense>
						}
					/>
					<Route
						path="/simulation/:tapeId"
						element={
							<Suspense
								fallback={<LoaderContainer width={50} />}
							>
								<SimulationContextProvider>
									<Simulation />
								</SimulationContextProvider>
							</Suspense>
						}
					/>
					<Route
						path="/library"
						element={
							<Suspense
								fallback={<LoaderContainer width={50} />}
							>
								<TapeSearch />
							</Suspense>
						}
					/>
					<Route
						path="/sanitize/:step/:tapeId"
						element={
							<Suspense
								fallback={<LoaderContainer width={50} />}
							>
								<SanitizationContextProvider>
									<SanitizeTape />
								</SanitizationContextProvider>
							</Suspense>
						}
					/>
					<Route
						path="/"
						element={
							<Suspense
								fallback={<LoaderContainer width={50} />}
							>
								<LandingPage />
							</Suspense>
						}
					/>
				</Route>

				<Route
					path="/create-password"
					element={
						isPasswordSet ? (
							<Navigate to={"/"} />
						) : (
							<Suspense
								fallback={<LoaderContainer width={50} />}
							>
								<CreatePassword />
							</Suspense>
						)
					}
				/>
			</Route>

			<Route
				path="/login"
				element={
					authStatus.isLoggedIn ? (
						<Navigate to={"/"} />
					) : (
						<Suspense
							fallback={<LoaderContainer width={50} />}
						>
							<Login />
						</Suspense>
					)
				}
			/>

			<Route
				path="/multi-factor-verification"
				element={
					<Suspense
						fallback={<LoaderContainer width={50} />}
					>
						<MultiFactor />
					</Suspense>
				}
			/>

			<Route
				path="*"
				element={<div>404</div>}
			/>
		</Routes>
	);
};
