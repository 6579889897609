import { Button, ButtonGroup } from "@mui/joy";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import { useCallback, useContext, useMemo } from "react";

import { SanitizationContext } from "../../contexts/sanitizationContext";
import textContent from "../../textContent/text.json";

const style = {
	position: "absolute" as const,
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	width: 500,
	bgcolor: "background.paper",
	boxShadow: 24,
	p: 4,
};

export default function ReSanitizeConfirmModal() {
	const {
		reSanitizeModalOpen,
		cancelReSanitize,
		handleContinue,
	} = useContext(SanitizationContext);

	const text = useMemo(
		() =>
			textContent.warningModals.reSanitizationConfirmation,
		[]
	);
	const handleReSanitize = useCallback(() => {
		cancelReSanitize();
		void handleContinue(true);
	}, [cancelReSanitize, handleContinue]);
	return (
		<div>
			<Modal
				open={reSanitizeModalOpen}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
			>
				<Box
					sx={style}
					className={"re-sanitize-confirmation-modal"}
				>
					<Typography
						id="modal-modal-title"
						variant="h6"
						component="h2"
					>
						{text.heading}
					</Typography>
					<Typography
						id="modal-modal-description"
						sx={{ mt: 1 }}
					>
						{text.description}
					</Typography>
					<Box className={"re-sanitize-btns"}>
						<Button
							className={"cancel"}
							onClick={cancelReSanitize}
						>
							{text.cancelBtnText}
						</Button>
						<Button
							className={"continue"}
							onClick={handleReSanitize}
						>
							{text.removeBtnText}
						</Button>
					</Box>
				</Box>
			</Modal>
		</div>
	);
}
