export const generatedPlotValues = (data: Array<any>) => {
	const result: any = {
		price: [],
		cashflow_url: [],
		graph_data: [],
		category: [],
	};
	data.forEach((element) => {
		if (
			element.assumptions &&
			typeof element.assumptions === "object"
		) {
			Object.entries(element.assumptions).forEach(
				([key, value]) => {
					if (result[key]) {
						result[key].push(value);
					} else {
						result[key] = [value];
					}
				}
			);
		}
		result.cashflow_url.push(element.cashflow_url);
		result.category.push(element.category);
		result.price.push(element.price);
		result.graph_data.push(element.graph_data);
	});

	return result;
};
