import { Box } from "@mui/material";
import { type FC } from "react";

export const Loader = (props: any) => (
	<svg
		viewBox="0 0, 100 100"
		width={props.width ?? "100"}
		className={`spinner ${props.className ?? ""}`}
	>
		<defs>
			<linearGradient
				id="linear"
				x1="0%"
				y1="0%"
				x2="100%"
				y2="0%"
				gradientTransform="rotate(10)"
			>
				<stop
					offset="10%"
					stopColor="#0377F5"
				/>
				<stop
					offset="100%"
					stopColor="#fefefe"
				/>
			</linearGradient>
		</defs>
		<path
			d="M 50 10 A 40 40 0 1 0 90 50"
			stroke="url(#linear)"
			strokeWidth={props.strokeWidth ?? "10"}
			fill="none"
			strokeLinecap="round"
		/>
	</svg>
);

type TLoader = {
	className?: string;
	withText?: boolean;
	heading?: string;
	text?: string;
};

export const LoaderWithText: FC<TLoader> = ({
	className,
	withText = false,
	heading,
	text,
}) => {
	return (
		<Box className={`loader ${className}`}>
			<Loader />
			{withText ? (
				<Box className="loader__text">
					<h3>{heading}</h3>
					<p>{text}</p>
				</Box>
			) : null}
		</Box>
	);
};

export const LoaderContainer = (props: any) => (
	<Box className={"loader-container"}>
		<Loader {...props} />
	</Box>
);
