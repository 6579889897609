import { Box } from "@mui/material";
import { useEffect, useRef, type FC } from "react";

export type TCoachMarkProps = {
	position:
		| "top"
		| "bottom"
		| "left"
		| "right"
		| "bottom-right";
	show: boolean;
	children: React.ReactNode;
};

export const CoachMark: FC<TCoachMarkProps> = ({
	children,
	position,
	show,
}) => {
	return show ? (
		<Box className={`coach-mark pos-${position}`}>
			{children}
		</Box>
	) : null;
};
