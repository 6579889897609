/**
 * Component that checks the authentication status and renders different content based on the status.
 * If the user is logged in, it renders the Layout component.
 * If the authentication status is still loading, it renders a loading message.
 * If the user is not logged in, it navigates to the login page.
 */
import { Box } from "@mui/material";
import { Navigate, Outlet } from "react-router-dom";

import { useAppSelector } from "./redux/store";
import { Loader } from "./shared/Loader";

export const AuthenticationCheck = () => {
	const { authStatus, user } = useAppSelector(
		(state) => state.user
	);

	return authStatus.isLoggedIn && user ? (
		<Outlet />
	) : authStatus.isLoading ? (
		<Box className={"page-loader"}>
			<Loader />
		</Box>
	) : (
		<Navigate
			to="/login"
			replace={true}
		/>
	);
};
